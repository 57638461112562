export const BASE_URL = 'https://test-api.myalt.shop:8000'
// export const BASE_URL = 'http://localhost:8080'
// export const BASE_URL =
//   'https://s7w528hw8h.execute-api.ap-south-1.amazonaws.com/dev'
// export const BASE_URL =
//   'https://xgrxgpe9m3.execute-api.ap-south-1.amazonaws.com/stg'
// export const BASE_URL =
//   'https://0zxvpaxnf8.execute-api.ap-south-1.amazonaws.com/prod'
// export const BASE_URL = 'https://api.myalt.shop'
export const APP_VERSION = '0.8.0'

export const RECAPTCHA_SITE_KEY = '6LcRaA8mAAAAAHiWcdtaBtl2Wx78MZUuYxoVHA3O'

export const gradientA =
  'bg-gradient-to-r from-[#BB5479] via-[#D87172] to-[#E68876]'
