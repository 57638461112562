// Import the functions you need from the SDKs you need
import { getAnalytics, isSupported } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA6B5usmyw-rRIcX2cWqBOe8TV9paBGGb8',
  authDomain: 'alt-store-prod.firebaseapp.com',
  projectId: 'alt-store-prod',
  storageBucket: 'alt-store-prod.appspot.com',
  messagingSenderId: '849978876054',
  appId: '1:849978876054:web:b58a319c4b6bd44ca762b7',
  measurementId: 'G-PRWMTWBC7E',
}

export const AMPLITUDE_ID = 'a50ce3f3102f033e7afc4bd366e213af'
export const SHOP_ID = 'alt-shop-dev'
export const CLARITY_ID = 'dr3cim84pj'

// export const AMPLITUDE_ID = '5b1813a4fd41b966878431492e59e697'
// export const SHOP_ID: 'alt-shop' | 'alt-shop-dev' = 'alt-shop'
// export const CLARITY_ID = 'ds7c2dqgm9'

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
export const analytics = isSupported().then((yes) =>
  yes ? getAnalytics(firebaseApp) : null,
)
export const auth = getAuth()
